<template>
    <div class="page-game-ai-summary">
        <div class="content-capture">
            <div class="capture-item" :class="[`${v.rule}-${v.boardSize}`]" v-for="(v, i) in captureGameList" :key="i"
            @mousedown="$event.currentTarget.classList.add('button-scale-down')"
            @touchstart="$event.currentTarget.classList.add('button-scale-down')"
            @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
            @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
            @touchend="$event.currentTarget.classList.remove('button-scale-down')"
            @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
            @click="gotoAction(v, 'capture')"
            >
                <div class="item-text">
                    <div class="main-title flex-center nowrap">
                        <span class="text-large-d bold">{{v.label}}</span>
                    </div>
                    <div class="sub-title flex-center nowrap bold">
                        <span class="text-normal-aa">{{v.intro}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-play">
            <div class="play-item" :class="[`${v.type}-${v.boardSize}`]" v-for="(v, i) in playGameList" :key="i"
            @mousedown="$event.currentTarget.classList.add('button-scale-down')"
            @touchstart="$event.currentTarget.classList.add('button-scale-down')"
            @touchleave="$event.currentTarget.classList.remove('button-scale-down')"
            @mouseleave="$event.currentTarget.classList.remove('button-scale-down')"
            @touchend="$event.currentTarget.classList.remove('button-scale-down')"
            @mouseup="$event.currentTarget.classList.remove('button-scale-down')"
            @click="gotoAction(v)"
            >
                <div class="item-text">
                    <div class="main-title flex-center nowrap">
                        <span class="text-large-a bold">{{v.label}}</span>
                    </div>
                    <div class="sub-title flex-center nowrap bold">
                        <span class="text-normal">{{v.intro}}</span>
                    </div>
                </div>
            </div>
        </div>
        <confirm-pro ref="confirm" @close="AILevelClosed">
            <div class="ai-level-select-box">
                <div class="level-sel-title text-center">
                    <span class="text-normal-aa bold">{{pageText['t21']}}</span>
                </div>
                <div class="level-sel-content">
                    <div class="con-slider-box">
                        <div class="slider-bcg vertical-align-center-position" :style="gressBcgStyle"></div>
                        <van-slider v-model="AILevelIndex"
                        :min="0" :max="selecteList.length - 1"
                        inactive-color="#EBEFF7"
                        active-color="#FE5D53"
                        bar-height="8px"
                        >
                            <template #button>
                                <div class="ai-sel-button">
                                </div>
                            </template>
                        </van-slider>
                        <div class="slider-step-box">
                            <div class="slider-step-item" v-for="v in selecteList" :key="v.id"></div>
                        </div>
                    </div>
                    <div class="ai-sel-title-box">
                        <div class="sel-title-item cursor-pointer flex-center"  
                        @click="AILevelSelectSlider({index: i})"
                        v-for="(v, i) in selecteList" :key="v.id" :class="{'active': v.id == AILevelVal.id}">
                            <span class="text-mini-bb no-selected bold" :class="{'bold': v.id == AILevelVal.id}">{{v.title}}</span>
                            <div class="sel-tit-icon align-center-position"></div>
                        </div>
                    </div>
                </div>
                <div class="level-sel-foot flex-center">
                    <sl-button @click="AILevelSelect(AILevelVal)" type="confirm">
                        <span class="text-normal">{{pageText.t22}}</span>
                    </sl-button>
                </div>
            </div>
        </confirm-pro>
        <confirm-pro ref="confirm_19" @close="AILevelClosed">
            <div class="ai-level-select-more">
                <div class="level-sel-title text-center">
                    <span class="text-normal-ba bold">{{pageText['t21']}}</span>
                </div>
                <div class="level-sel-more flex-center">
                    <div class="content-body flex-center">
                        <div class="level-control-btn scale-button left" @click="levelMove(-1)"></div>
                        <div class="level-control-content mini-scrollbar-x" ref="levelScrollBox" 
                        @mousewheel="levelWheel"
                        >
                            <div class="con-content-body no-selected" :style="{width: `${selecteList.length * 20}%`, ...levelStyle, ...levelTemStyle}">
                                <div class="level-data-item cursor-pointer" v-for="(v, i) in selecteList" :key="v.level"
                                @click="setLevel(i)"
                                :style="{width: `${(20 / (selecteList.length * 20)) * 100}%`}"
                                >
                                    <div class="item content flex-center">
                                        <span class="text-normal-aa bold">{{v.title}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="level-select-area">
                            <div class="area-bcg item"></div>
                            <div class="area-flag item"></div>
                            <div class="area-bcg-white item"></div>
                        </div>
                        <div class="level-control-btn scale-button right" @click="levelMove(1)"></div>
                    </div>
                </div>
                <div class="level-sel-foot flex-center">
                    <sl-button @click="AILevelSelect(AILevelVal)" type="confirm">
                        <span class="text-normal">{{pageText.t22}}</span>
                    </sl-button>
                </div>
            </div>
        </confirm-pro>
        <!-- <shadowBcg></shadowBcg> -->
    </div>
</template>
<script>
    import apiClass from '@/API/page/game'
    // import shadowBcg from '../background/AIShadow.vue'
    import BScroll from '@better-scroll/core'

    export default {
        name: '',
        props: {
        },
        data() {
            return {
                appData: new this.appDataClass({key: 'game-action'}),
                appDataList: new this.appDataClass({key: 'game-list'}),
                api: new apiClass(),

                gameList: [],
                aiList: [],
                selected: {},
                AILevelResponse: null,
                AILevelIndex: null,
                pageIsInit: false,
                levelTouchState: false,
                levelItemSize: 0,
                touchData: {},
                levelScroll: null,
                localData: {}
            };
        },
        methods: {
            async gotoAction(val, type){
                val = val || {}
                let levelData = await this.getAILevel(val)
                let {id, AILevel, gameType, boardSize} = levelData
                let data = {id}
                // let {AILevel, style, api, id} = levelData
                // let {boardSize, captureNum, moveNum, gameType, komi, rule = 'chinese', judgeMove, judgeSplit} = val
                // let data = {boardSize, captureNum, moveNum, gameType, komi, rule, AILevel, style, api, id}
                // if(judgeMove) data.judgeMove = judgeMove
                // if(judgeSplit) data.judgeSplit = judgeSplit
                for(let i in data){
                    if(data[i] === undefined) delete data[i]
                }
                this.appData.removeAll()
                let routeData = this._common.encodeData(data)
                let opt = {
                    name: 'game-action',
                    params: {
                        routeData,
                    }
                }
                let level = {}
                level[`${gameType}_${boardSize}_level`] = AILevel
                this.localDataSave(level)
                this._routeHand.goto(opt)
            },
            async getAILevel(val){
                val = val || {}
                let  {AILevel} = val
                this.selected = val
                await this._common.nextTick.call(this)
                let list = this.selecteList
                if(!list.length) return {AILevel}
                if(list.length == 1){
                    let data = list[0]
                    AILevel = data.level
                    return {AILevel}
                }
                return new Promise(res => {
                    this.AILevelResponse = res
                    this.ALLevelIndexInit()
                    let confirmType = this.isMoreStyle ? 'c11' : 'c4'
                    let {boardSize} = this
                    let ele = this.$refs.confirm
                    if(this.$refs[`confirm_${boardSize}`]) ele = this.$refs[`confirm_${boardSize}`]
                    ele.open({
                        closeOnClickOverlay: true,
                        confirmType,
                        showClose: true
                    })
                    this.itemSizeSet()
                    this.appendSliderStep()
                    this.levelScrollSet()
                })

                
            },
            ALLevelIndexInit(){
                if(this.AILevelIndex !== null) return
                this.AILevelIndex = Number(this.AILevelIndexDefault)
            },
            async pageInit(){
                await this.patternListInit()
                await this.levelListInit()
                this.localDataSet()
                this.pageIsInit = true
            },
            async patternListInit(){
                let res = await this.api.configLoad('game')
                if(res) this.gameList = res
            },
            async levelListInit(){
                let res = await this.api.getLevelList()
                if(!res) return
                this.aiList = res
            },
            AILevelSelect(val){
                let {eloScore: AILevel, style, api, id} = val || {}
                let reData = {AILevel, style, api, id}
                if(this.AILevelResponse) this.AILevelResponse(reData)
                this.$refs['confirm'].close()
                return reData
            },
            AILevelClosed(){
                this.AILevelResponse = null
            },
            AILevelSelectSlider(val){
                let {index} = val || {}
                this.AILevelIndex = Number(index)
            },
            async appendSliderStep(){
                await this._common.nextTick.call(this)
                let ele = document.querySelector('.page-game-list .ai-level-select-box .level-sel-content .con-slider-box .slider-step-box')
                let eleBox = document.querySelector('.page-game-list .ai-level-select-box .level-sel-content .con-slider-box .van-slider')
                if(!ele || !eleBox){
                    return
                }
                eleBox.appendChild(ele)
            },
            changeGameType(val){
                let {value, state} = val
                if(!state) return
                this.gameTypeVal = value
            },
            levelTouchdown(e){
                this.levelTouchState = true
                let {clientX: x, clientY: y} = e
                this.touchData = {x, y}
            },
            levelTouchup(){
                this.AILevelIndex = this.indexTem
                this.levelTouchState = false
                this.touchData = {}
            },
            levelTouchmove(e){
                if(!this.levelTouchState) return
                let {clientX: x} = e
                let delta = this.touchData.x - x
                // this.touchData.delta = delta
                this.$set(this.touchData, 'delta', delta)
            },
            levelMove(num){
                let {AILevelIndex: index, selecteList: list} = this
                index = Number(index) + num
                if(index < 0) index = 0
                if(index >= list.length) index = list.length - 1
                this.AILevelIndex = index
            },
            async itemSizeSet(){
                await this._common.nextTick.call(this)
                let ele = document.querySelector('.page-game-list .ai-level-select-more .level-sel-more .content-body .level-control-content .level-data-item')
                if(!ele) return
                let width = ele.offsetWidth
                this.levelItemSize = width
            },
            setLevel(index){
                this.AILevelIndex = index
            },
            levelWheel(e){
                let {deltaY} = e
                let num = deltaY > 0 ? 1 : -1
                this.levelMove(num)
            },
            levelScrollSet(){
                if(!this.isMoreStyle) return
                let ele = this.$refs.levelScrollBox
                if(!ele) return
                this.levelScroll = new BScroll('.page-game-list .ai-level-select-more .level-sel-more .content-body .level-control-content', {
                // ...... 详见配置项
                scrollX: true
                })

            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('onresize', e => {
                    this.itemSizeSet()
                })
            },
            eventOff(){
                this._common.eventRemove('onresize')
            },
            localDataSave(items){
                this.appDataList.setItem(items)
                this.localDataSet()
            },
            localDataSet(){
                let data = this.appDataList.getAll()
                this.localData = data || {}
            },
        },
        created() {
            this.pageInit()
        },
        components: {
            // shadowBcg
        },
        computed: {
            indexTem(){
                let {index, touchData: {delta}, levelItemSize: size, selecteList: list} = this
                delta = delta || 0
                let num = parseInt(delta / size)
                let d = delta % size
                if(Math.abs(d) >= (size / 2)){
                    num = delta > 0 ? num++ : num--
                }
                index += num
                if(index >= list.length) index = list.length - 1
                if(index < 0) index = 0
                return index
            },
            indexPos(){
                let {AILevelIndex: index, levelItemSize: size} = this
                let pos = index * -size + size * 2
                return pos
            },
            levelTemStyle(){
                let {touchData: {delta}} = this
                if(!delta || isNaN(Number(delta))) return {}
                return {
                    transform: `translateX(${-delta}px)`
                }
            },
            levelStyle(){
                let {indexPos: pos} = this
                return {
                    // transform: `translateX(${pos}px)`
                    left: `${pos}px`
                }
            },
            isNormalStyle(){
                return this.boardSize != 19
            },
            isMoreStyle(){
                return this.boardSize == 19
            },
            boardSize(){
                let {boardSize} = this.selected || {}
                return boardSize
            },
            gressBcgStyle(){
                let {AILevelIndex: index, selecteList: list} = this
                let data = index / (list.length - 1)
                return {
                    width: `${data * 100}%`
                }
            },
            AILevelVal(){
                let {selecteList: list, AILevelIndex: index} = this
                let data = list[index] || {}
                return data
            },
            AILevelIndexDefault(){
                let {type, boardSize} = this.selected
                let key = `${type}_${boardSize}_level`
                let localLevel = this.localData[key]
                if(localLevel === undefined || isNaN(Number(localLevel))) localLevel = undefined
                let {selecteList: list} = this
                let index = null, defaultIndex = null
                for(let i in list){
                    if(localLevel !== undefined && localLevel == list[i].level) index = i
                    if(list[i].default) defaultIndex = i
                }
                if(index !== null) return index
                if(defaultIndex !== null) return defaultIndex
                return 0
            },
            selecteList(){
                let {type, boardSize, styleType} = this.selected || {}
                if(!type || !boardSize) return []
                let {levelSelectList: list} = this
                let data = []
                for(let i in list){
                    let {type: seType, boardSize: size} = list[i]
                    if((seType != type && seType != styleType) || size != boardSize) continue
                    data.push(list[i])
                }
                data = this._common.sortData(data, (v1, v2) => {
                    let {level: level1} = v1
                    let {level: level2} = v2
                    return level1 < level2
                })
                return data
            },
            levelSelectList(){
                let types = this.PARAMS.game_level_keys
                let {aiList: list} = this
                let data = []
                for(let i in list){
                    let {description} = list[i]
                    if(!this._common.isObject(description)) continue
                    let {type} = description
                    if(!types[type]) continue
                    let info = list[i]
                    let level = info.level || info.eloLevel || info.eloScore || 0
                    data.push({...list[i], ...description, level})
                }
                return data
            },
            captureGameList(){
                let list = this.gameList
                let rd = []
                for(let i in list){
                    let tem = this._common.deepCopy(list[i])
                    let detail = this._common.deepCopy(tem.resourceDetail)
                    let {type} = detail
                    if(type != 'capture') continue
                    
                    delete tem['resourceDetail']
                    tem.gameType = type
                    rd.push({...detail, ...tem})
                }
                return rd
            },
            playGameList(){
                let list = this.gameList
                let rd = []
                for(let i in list){
                    let tem = this._common.deepCopy(list[i])
                    let detail = this._common.deepCopy(tem.resourceDetail)
                    let {type} = detail
                    if(type != 'play') continue
                    
                    delete tem['resourceDetail']
                    tem.gameType = type
                    rd.push({...detail, ...tem})
                }
                return rd
            },
        },
        watch: {
            selected: {
                handler(val, oldVal){
                    if(this._common.isSameObject(val, oldVal)) return
                    this.AILevelIndex = null
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-game-ai-summary{
        .content-capture{
            position: absolute;
            top: 57px;
            left: 67px;
            .capture-item{
                position: relative;
                .item-text{
                    position: absolute;
                }
                .main-title{
                    height: 26px;
                    margin-bottom: 9px;
                }
            }
            .capture-item+ .capture-item{
                margin-top: 11px;
            }
            .capture-item.capture-9{
                width: 294px;
                height: 130px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g8.png'));
                .item-text{
                    top: 40px;
                    left: 29px;
                    width: 121px;
                    text-align: center;
                }
            }
            .capture-item.capture-13{
                width: 309px;
                height: 129px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g9.png'));
                .item-text{
                    position: absolute;
                    top: 40px;
                    left: 25px;
                    width: 129px;
                    text-align: center;
                }
            }
        }
        .content-play{
            position: absolute;
            top: 52px;
            left: 363px;
            .play-item{
                position: relative;
                .item-text{
                    top: 26px;
                    position: absolute;
                }
                .main-title{
                    height: 20px;
                    margin-bottom: 8px;
                    text-align: center;
                }
            }
            .play-item.play-9{
                width: 259px;
                height: 93px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g10.png'));
                .item-text{
                    left: 22px;
                    width: 76px;
                }
            }
            .play-item.play-13{
                margin-left: 12px;
                margin-top: 1px;
                width: 263px;
                height: 93px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g11.png'));
                .item-text{
                    top: 24px;
                    left: 21px;
                    width: 84px;
                }
            }
            .play-item.play-19{
                margin-left: 24px;
                margin-top: -4px;
                width: 270px;
                height: 96px;
                .background-img-max(url('@{assetsUrlV2_0}/game/g12.png'));
                .item-text{
                    top: 28px;
                    left: 21px;
                    width: 84px;
                }
            }
        }
    }
</style>